import { baseUrl } from '../config/baseUrl';
import { payment_providers_names } from '../utils/enums';

export const GetProviderConfig = (provider) => {
    var url = baseUrl.earth;
    var name = 'earth';

    if (provider === payment_providers_names.OPTOMANY) {
        url = baseUrl.pluto;
        name = 'pluto';
    } else if (provider === payment_providers_names.CHECKOUT_HF) {
        url = baseUrl.checkout;
        name = 'checkout/pay';
    }

    return { url, name }
}
