import React, { useState, useEffect } from 'react';
import { baseUrl, envVar } from '../../../config/baseUrl';
import { errerMessages } from "../../../helpers";
import axios from 'axios';
import GooglePayButton from '@google-pay/button-react';

export default function GPay({ encryptData, base64Data, data, setError, setMessage, setFailedMessage, setLoading }) {
  const [allowedCardNetworks, setAllowedCardNetworks] = useState(envVar.gPaySupportedCardNetworks?.split(','));
  const totalPrice = parseFloat(data?.total)?.toFixed(2);

  const gpayPaymentInititate = async (dataObj) => {
    try {
      setLoading(true);
      const url = `${baseUrl.checkout}/token-pay?via=ZnJvbnRlbmQtZ3BheQ==`;
      const response = await axios.post(url, { ...dataObj }).then(res => res);
      console.log('response status: ', response.status);
      if (response.status === 202) {
        let redirect_url = response?.data?.data?.payments?._links?.redirect?.href;
        if (!redirect_url && response.data?.message?.includes('has been already paid')) {
          redirect_url = data?.redirect_url;
        }
        console.log("redirect_url: ", redirect_url);
        redirect_url && window.location.replace(redirect_url);
      } else if (response.status === 201) {
        let redirect_url = data?.redirect_url;
        redirect_url && window.location.replace(redirect_url);
      } else {
        setLoading(false);
        setError(true);
        setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #GP705`);
        setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.log('error', error);
      setLoading(false);
      setError(true);
      if (error?.response?.status === 422) {
        setFailedMessage(error?.response?.data?.message);
      } else {
        setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #GP706`);
        setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
      }
    }
  };

  // Update allowedCardNetworks based on the exclude_card_networks once the component is mounted
  useEffect(() => {
    let cardList = envVar.gPaySupportedCardNetworks?.split(',') || ["AMEX", "DISCOVER", "INTERAC", "JCB", "MASTERCARD", "VISA"];

    const { exclude_card_networks } = data;
    if (exclude_card_networks) {
      // Convert the exclude_card_networks to uppercase and split it into an array
      const excludeCardNetworksArray = exclude_card_networks?.toUpperCase()?.split(',');
      // Remove the excluded card networks from the allowed card networks
      const filteredList = cardList.filter(card => !excludeCardNetworksArray.includes(card));
      cardList = filteredList;
    }
    setAllowedCardNetworks(cardList);
  }, [data]);

  const gpayHandlePay = async (token) => {
    const gpayData = {
      data: encryptData,
      token: JSON.parse(token),
      base64Data
    };

    console.log("gpayData", gpayData);
    await gpayPaymentInititate(gpayData);
  };

  return (
    <GooglePayButton
      environment={envVar.gPayEnv}
      buttonType='pay'
      buttonSizeMode='fill'
      paymentRequest={{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: allowedCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'checkoutltd',
                gatewayMerchantId: envVar.gPayPublishKey,
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: envVar.gPayMerchantId,
          merchantName: envVar.gPayMerchantName,
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: totalPrice,
          currencyCode: data?.currency_name,
          countryCode: data?.country_code,
        }
      }}
      onLoadPaymentData={paymentRequest => {
        gpayHandlePay(paymentRequest?.paymentMethodData?.tokenizationData?.token);
      }}
      onError={error => {
        console.error('onError', error);
      }}
    />
  )
}
