import axios from 'axios';
import { baseUrl, envVar } from '../../config/baseUrl';
import { payment_providers_names } from '../../utils/enums';

export const getAdyenPaymentSession = async (payload, setMessage) => {
    const response = await axios.post(`${baseUrl.antar}/antar/hosted-form/create-session`, { data: payload });
    // console.log('Adyen Response: ', response);
    const { data, message } = response.data;
    setMessage(message);
    return data;
}

export const validateMerchant = async (validationURL) => {
    const payload = {
        appleUrl: validationURL,
        merchantIdentifier: envVar.aPayAdyenMerchantIdentifier,
        domainName: envVar.aPayDomainName,
        displayName: envVar.aPayMerchantDisplay,
        gateway: payment_providers_names.ADYEN_HF
    };
    return await axios.post(`${baseUrl.switch}/validate-session`, { ...payload });
}
