import React from "react";
import { useLocation } from "react-router-dom";
import CheckoutPollingForm from "./CheckoutPollingForm";

function PollStatus(props) {
  const location = useLocation();

  // Check for token in the state or query parameters
  const token = location.state?.token || new URLSearchParams(location.search).get("token");

  if (!token) {
    console.error("Token is missing.");
    return <div>Error: Token is missing.</div>; // Render a user-friendly message
  }

  return <CheckoutPollingForm token={token} />;
}

export default PollStatus;
