import React, { isValidElement, useEffect, useState } from 'react';
import Spinner from '../../common/Spinner';
import queryString from 'query-string';
import { card_types } from '../../../utils/enums';
import axios from 'axios';
import { envVar } from '../../../config/baseUrl';
import {
  errerMessages,
  spinnerMessage,
} from '../../../helpers/error-messages-enum';
import ErrorMessage from '../../common/ErrorMessage';
import '../../../components/common/ErrorMessage/styles.css';

const Switch = () => {
  const [data, setData] = useState(null);
  const [mode, setMode] = useState(null);
  const [decodedData, setDecodedData] = useState({});
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(true);
  const [orderAlrdyProcessed, setOrderAlrdyProcessed] = useState(false);
  const [apiFailuerMessage, setApiFailureMessage] = useState('');
  let url = window.location.href;
  const params = queryString.parseUrl(url);

  const getSaveCardDetails = async (encryptedData, via, base64Data, parsedData) => {
    setLoader(true);
    try {
      let url = '';
      if (via == card_types.NEW_CARD) {
        console.log('base64Data',isValidElement(base64Data));
        let newCardUrl = `${envVar.saturnBaseUrl}/new-card-sale?data=${encryptedData}&base64Data=${base64Data}`;
        window.location.replace(newCardUrl);
      } else {
        console.log('base64Data',isValidElement(base64Data));
        url = `${envVar.saturnBaseUrl}/save-card-sale`;
        console.log('decodedData', parsedData);
        const response = await axios.post(url, {
          data: encryptedData,
          base64Data: base64Data,
        });
        console.log('save card sale response', response);
        if (response.status === 200 || response.status === 201) {
          if (response.data.statusCode == 1201) {
            setOrderAlrdyProcessed(!orderAlrdyProcessed)
            setTimeout(() => {
              window.location.replace(parsedData.redirect_url);
            }, 3000);
          } else {
            window.location.replace(response.data.next_page_url.url);
          }
        }
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.status == 500) {
        const orderId = error.response.data.error.orderId
        setMessage(error.response.data.error.message);
        setApiFailureMessage(errerMessages.ERROR_REQUEST_MSG(orderId))
      } else {
        setApiFailureMessage(errerMessages.CANNOT_PROCESS_PAYMENT)
      }
      console.log('error: ', error);
    }
  };

  useEffect(() => {
    const { data, via, response } = params.query;
    const encryptedData = params.query.data;
    if(!response || !data || !via){
      setApiFailureMessage(errerMessages.CANNOT_PROCESS_PAYMENT)
      setLoader(false);
    } else {
      const decoded = Buffer.from(response, 'base64').toString('utf8');
      const parsedData = JSON.parse(decoded);
      setData(data);
      setMode(via);
      setDecodedData(parsedData);
      getSaveCardDetails(encryptedData, via, response, parsedData);
    }
  }, []);

  console.log('cancel_url', decodedData.cancel_url)
  return (
    <div>
      {loader ? (
        <Spinner
          message={
            orderAlrdyProcessed
              ? spinnerMessage.ORDER_ALRDY_PROCESSED
              : spinnerMessage.LOADING
          }
        />
      ) : (
        <div className="mainContainer">
          <ErrorMessage
            cancleUrl={decodedData.cancel_url}
            failedMessage={apiFailuerMessage}
            message={message}
          />
        </div>
      )}
    </div>
  );
};

export default Switch;
