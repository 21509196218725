import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import Spinner from '../common/Spinner';
import { Container, Card } from 'react-bootstrap';
import { baseUrl } from '../../config/baseUrl';
import { getDecodedData, goBackButton } from '../../helpers';
// import { configureBrowserInfo, spreedlyLifecycleHandler, updateUI } from './helper-functions';
import HostedForm from './hosted-form';
import MarsSaveCard from './save-card';
const axios = require('axios');

export default function PaymentPage(props) {

    const [message, setMessage] = useState('Processing payment...');
    const [loading, setLoading] = useState(true);
    const [failure, setFailure] = useState(false);
    const [token, setToken] = useState(null);
    const [encryptedData, setEncryptedData] = useState('');
    const [base64, setBase64] = useState('');
    const [decodedData, setDecodedData] = useState(null);
    const [via, setVia] = useState(0);
    const [isChecked, setIsChecked] = useState(true);

    useEffect(() => {
        const url = window.location.href;
        const params = queryString.parseUrl(url);
        const encryptedData = params.query.data;
        const base64Data = params.query.response;
        const via = params.query.via;
        setEncryptedData(encryptedData);
        setBase64(base64Data);
        setVia(via);

        setLoading(false);

        const decodeData = async () => {
            const { decodedString } = await getDecodedData(base64Data);
            setDecodedData(decodedString);
        };
        decodeData();
    }, []);

    useEffect(() => {
        if (encryptedData && token) {
            makePurchase(token);
        }
    }, [encryptedData, token]);

    const makePurchase = async (token, cvv) => {
        try {
            setLoading(true);
            const url = `${baseUrl.marsUrl}/hosted/sale`;
            // const browserInfo = await configureBrowserInfo();    // used for 3DSv2 txn
            const response = await axios.post(url, { data: encryptedData, token, v: cvv, save_card: isChecked }).then(res => res.data);
            console.log('response', response);
            let returnUrl = response?.returnUrl;
            returnUrl && window.location.replace(returnUrl);
            // state from backend API
            /* used for 3DSv2 txn
            switch (response?.state) {
                case "succeeded":
                    // transaction succeeded w/o the banks requiring 3DS authentication!
                    console.log('transaction succeeded w/o the banks requiring 3DS authentication!');
                    updateUI({
                        token: response.token,
                        message: response.message
                    }, true);
                    break;
                case "failed":
                    // Authentication failed
                    console.log('Authentication failed');
                    updateUI({
                        token: response.token,
                        message: response.message
                    }, false);
                    break;
                case "error":
                    console.log(response);
                    break;
                case "pending":
                    // looks like user will have to authenticate, no worries, Spreedly Lifecycle has you covered.
                    await spreedlyLifecycleHandler(response.token);
                    break;
            }
            */
        } catch (error) {
            console.error("error: ", error);
            setFailure(true);
            if (error.response?.data) {
                const { message, redirect_url } = error.response.data?.error;
                setMessage(message);
                redirect_url && setTimeout(() => { window.location.replace(redirect_url); }, 2000);
            } else {
                setMessage(error.message);
            }
        }
    };

    return !failure ? (
        loading ? <Spinner message={message} /> : (
            via === '1' ? <MarsSaveCard decodedData={decodedData} base64Data={base64} makePurchase={makePurchase} /> : <HostedForm setToken={setToken} decodedData={decodedData} isChecked={isChecked} setIsChecked={setIsChecked} />
        )
    ) : (
        <Container className="py-4" style={{ minWidth: '320px', maxWidth: '550px', margin: 'auto' }}>
            <div className="back-btn-container btn btn-light" onClick={() => goBackButton(`/mars?data=${encryptedData}&response=${base64}&via=${via}`)}>« Back</div>
            <Card className="text-center border-danger  alert-danger ">
                <Card.Body><div className='text-danger' style={{ fontSize: '18px' }}>{message}</div></Card.Body>
            </Card>
        </Container>
    )
}
