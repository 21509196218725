export const alphaNumericSpecial = (value) => {
    var regex = /^[a-zA-Z0-9\-\.',&\/#+?\s]+$/;
    if (regex.test(value)) return true;
    return false;
};

export const validateAddress = (value) => {
    let data = value.replace(/[^a-zA-Z0-9\-\.\s,'&\/#+?]+/g, '');
    return data;
};

export const validatePostcode = (value) => {
    let data = value.replace(/[^A-Z\d\-\s_]+/g, '');
    return data;
};

export const allowOnlyNumber = (value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
        return true;
    }
};

export const alphaNumeric = (value) => {
    var regex = /^[a-z\d\-_\s]+$/i;
    if (regex.test(value)) return true;
    return false;
};