import axios from "axios";
import {threeDsRedirect} from './index';

export const initiateSale = async (
  appendedData,
  setMessage,
  setLoading,
  setError,
  setFailedMessage,
  providerUrl,
  data
) => {
  try {
    const url = `${providerUrl}/sale/token/create`;

    const response = await axios.post(url, { ...appendedData });

    if (response.status === 200) {
 

      const { redirectUrl } = response.data.data;

      window.location.replace(redirectUrl);
    } else if (response.status === 201) {
      const { success, threeDSreq } = response.data.data;
 
      if (success === "3d") {
        await threeDsRedirect.threeDsV1AcsRedirect(threeDSreq, {
          orderId: response?.data?.data?.order_id,
        });
      } else if (success === "3dsV2") {
        // window.DD_LOGS.logger.info(`3dsv2 state debug FE received response on 3ds v2 ${response?.data?.data?.order_id} `, {})
        if (threeDSreq.hasOwnProperty("creq")) {
          // window.DD_LOGS.logger.info(`Direct creq received for 3ds v2 ${response?.data?.data?.order_id} `, {})
          threeDsRedirect.AcsRedirect({
            action: "creq",
            threeDSURL: threeDSreq.threeDSURL,
            creq: threeDSreq.creq,
          });
        } else if (threeDSreq.hasOwnProperty("threeDSMethodData")) {
          // window.DD_LOGS.logger.info(`Response received for Fingerprinting on 3ds v2 ${response?.data?.data?.order_id} `, {})
          await threeDsRedirect.threeDsV2AcsRedirect(threeDSreq, {
            orderId: response?.data?.data?.order_id,
          });
        } else {
          // window.DD_LOGS.logger.info(`neither received creq or threeDSMethodData for 3ds v2 ${response?.data?.data?.order_id} `, {})
        }
      }
      return false;
    }
  } catch (error) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.errorResponse &&
      error.response.data.errorResponse.error
    ) {
      const { message, failedMessage } =
        error.response.data.errorResponse.error;
      if (message === "Payment already done" && error.response.status === 400) {
        setTimeout(() => {
          window.location.replace(data.redirect_url);
        }, 2000);
      } else {
        setMessage(message);
        setLoading(false);
        setError(true);
        setFailedMessage(failedMessage);
      }
    } else {
      setMessage('payment failed');
      setLoading(false);
      setError(true);
      setFailedMessage('');
    }
  }
};
