import React, { useEffect, useState } from "react";
import axios from "axios";
import Spinner from "../../common/Spinner";
import { baseUrl, envVar } from "../../../config/baseUrl"; // Importing baseUrl
import "./style.css";

export default function CheckoutPollingForm({ token }) {
  const [retrying, setRetrying] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const delay = (ms) => new Promise((res) => setTimeout(res, ms)); // Utility function to introduce delay

  // Function to poll the order status up to x times with a y-second interval
  const pollForStatus = async () => {
    let attempts = 0;

    while (attempts < envVar.egTimeoutRetryCount) {
      try {
        const response = await axios.get(`${baseUrl.earth}/get-order-status?status=retry&token=${token}`);

        if (response.status === 200) {
          // Parse the response body as it's a stringified JSON
          const responseBody = JSON.parse(response.data.body);

          // Check if redirect_url exists in the response
          if (responseBody.redirect_url) {
            // Redirect to the URL
            window.location.replace(responseBody.redirect_url);
            return;
          } else if (responseBody.message) {
            console.log(responseBody.message);
          }
        } else {
          console.error(`Unexpected status code: ${response.status}`);
          setErrorMessage("An unexpected error occurred. Please try again later...");
          return;
        }
      } catch (error) {
        console.error(`Polling attempt ${attempts + 1} failed:`, error);
        setErrorMessage("An unexpected error occurred. Please try again later...");
      }

      attempts++;
      await delay(envVar.egTimeoutRetryInterval); // Wait for 5 seconds before retrying
    }

    // If not successful after retries, call the timeout logic
    handleTimeout();
  };

  // Function to handle timeout logic
  const handleTimeout = async () => {
    try {
      const timeoutResponse = await axios.get(`${baseUrl.earth}/get-order-status?status=timeout&token=${token}`);

      if (timeoutResponse.status === 200) {
        // Parse the response body as it's a stringified JSON
        const responseBody = JSON.parse(timeoutResponse.data.body);

        // Redirect to failure_url if the status is timeout
        if (responseBody.redirect_url) {
          window.location.replace(responseBody.redirect_url);
        }
      } else {
        console.error(`Unexpected status code on timeout request: ${timeoutResponse.status}`);
        setErrorMessage("An unexpected error occurred. Please try again later.");
      }
    } catch (error) {
      console.error("Error redirecting to timeout URL:", error);
      setErrorMessage("An unexpected error occurred. Please try again later...");
    }
  };

  // Start polling automatically when the component mounts
  useEffect(() => {
    setRetrying(true);
    pollForStatus();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <div className="polling-container">
      {errorMessage ? (
        <div className="error-message-box">
          <p className="error-text">{errorMessage}</p>
        </div>
      ) : (
        <div className="polling-message-box">
          <Spinner />
          <p className="polling-main-message">
            <strong>Checking your payment status, please wait...</strong>
          </p>
          <p className="polling-sub-message">
            Please don't refresh or press the back button while we process your payment.
          </p>
        </div>
      )}
    </div>
  );
}
