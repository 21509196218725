import React, { useState, useEffect } from 'react';
import GPay from '../GooglePay';
import APay from '../ApplePay';
import './style.css';

export default function GpayApayContainer({ data, encryptData, base64Data, setError, setMessage, setFailedMessage, setLoading }) {
    const [gpayComponent, setGpayComponent] = useState(null);
    const [apayComponent, setApayComponent] = useState(null);

    useEffect(() => {
        if (data.gpay_apay_enabled) {
            setGpayComponent(<GPay
                encryptData={encryptData}
                base64Data={base64Data}
                data={data}
                setError={setError}
                setMessage={setMessage}
                setFailedMessage={setFailedMessage}
                setLoading={setLoading}
            />);

            // Apple pay button enabled based on flag
            if (data.apay_enabled) {
                setApayComponent(<APay
                    encryptData={encryptData}
                    base64Data={base64Data}
                    decodedString={data}
                    setError={setError}
                    setMessage={setMessage}
                    setFailedMessage={setFailedMessage}
                    setLoading={setLoading}
                />);
            }
        }
    }, [encryptData, base64Data, data, setError, setMessage, setFailedMessage, setLoading]);

    return (
        gpayComponent ?
            <>
                <div className='gpay-apay-button-container'>
                    {gpayComponent}
                    {apayComponent}
                </div>
                <div className='partition-container'>
                    <span className='partition-text'>Or pay using your card</span>
                </div>
            </> :
            null
    )
}
