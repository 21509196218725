import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { goBackButton } from '../../../helpers';
import Spinner from '../../common/Spinner';
import FailurePage from '../../earth-gateway/FailurePage';
import { processing_messages } from '../../../utils/enums'
import GpayApayContainer from '../../checkout/GpayApayContainer';

const SaveCardRedirect = ({ data, response, decodedData, redirectToNewCard, renderCard })=>{
  const [message, setMessage] = useState('');
  const [failedMessage, setFailedMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

    return !error ? (
    !loading ? (<Container className='col d-flex justify-content-center mt-4'>
            <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
              <div className="btn btn-light back-btn-container" onClick={() => goBackButton(decodedData?.back_url)}>« Back</div>
              <GpayApayContainer data={decodedData} encryptData={data} base64Data={response} setError={setError} setMessage={setMessage} setFailedMessage={setFailedMessage} setLoading={setLoading} />
              <p> Your Saved Credit and Debit Cards</p>
              {renderCard}
              <div className='text-center mt-4'>
              <Link to="#" onClick={redirectToNewCard} className='nav-link '>
                  Add New Card{' '}
                  <p>
                    <i className='fas fa-plus-circle'></i>
                  </p>
                  </Link>
              </div>
              <footer className='text-center text-muted footer-size mt-3'>
                Powered by <span className='font-weight-bold'>Datman</span>
              </footer>
            </div>
          </Container> ) : (
          <Spinner message={processing_messages.LOADING_MESSAGES} />
      )
    ) : (
      <FailurePage
        encryptData={decodedData}
        message={message}
        cancelUrl={decodedData?.cancel_url}
        base64Data={response}
        dataDecode={data}
        failedMessage={failedMessage}
      />
    )
  };

  export default SaveCardRedirect;
  