export const errerMessages = {
  CANNOT_PROCESS_PAYMENT:
    'Sorry, your payment cannot be processed at the moment',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  ERROR_REQUEST_MSG: (id) =>
    `Your payment for order #${id} was not completed. If an amount was debited, it will be refunded to your card with 3 to 5 working days.`,
};
export const spinnerMessage = {
  REDIRECT_MESSAGE: 'Please wait...',
  LOADING: 'Loading...',
  ORDER_ALRDY_PROCESSED:
    'Order already processed. Redirecting you to the success page.',
};
export const delayMessage = {
  LONG_DELAY_MESSAGE:
    'oops, taking longer than expected, hold on. Payment will be succedeed.',
};

export const buttonTxts = {
  TRY_AGAIN: 'Try again',
  CANCEL: 'Cancel',
};
