import React, { useEffect, useState } from "react";
import AddCardContainer from "./AddCardContainer";
import queryString from 'query-string';

function AddCardPage() {
    const [encryptedData, setEncryptedData] = useState('')
    const [base64Data, setBase64Data] = useState('');

    useEffect(() => {
        let url = window.location.href;
        const { query } = queryString.parseUrl(url);
        const { data, base64Data } = query;
        setEncryptedData(data);
        setBase64Data(base64Data);
    }, []);

    return <AddCardContainer
        encryptedData={encryptedData}
        base64Data={base64Data}
    />
}

export default AddCardPage;