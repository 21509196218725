import React, { isValidElement, useEffect, useState } from 'react';
import Spinner from '../../common/Spinner';
import queryString from 'query-string';
import {
  saturn_redirect_method,
  transaction_status,
} from '../../../utils/enums';
import axios from 'axios';
import { envVar } from '../../../config/baseUrl';
import {
  delayMessage,
  spinnerMessage,
  errerMessages
} from '../../../helpers/error-messages-enum';
import ErrorMessage from '../../common/ErrorMessage';
import '../../../components/common/ErrorMessage/styles.css';

export default function SaturnRedirectSC() {
  const [data, setData] = useState(null);
  const [via, setVia] = useState(null);
  const [decodedData, setDecodedData] = useState({});
  const [count, setCount] = useState(0);
  const [message, setMessage] = useState('');
  const [apiFailuerMessage, setApiFailureMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  let url = window.location.href;
  const params = queryString.parseUrl(url);
  const encryptedData = params.query.data;

  const getApiCall = async (encryptedData, via, base64Data, parsedData) => {
    try {
      let url = '';
      if (via == saturn_redirect_method.SAVECARD) {
        url = `${envVar.saturnBaseUrl}/poll-new-pm-and-charge`;
        const response = await axios
          .post(url, { data: encryptedData, base64Data: base64Data })
          .then((res) => res.data);
        console.log('poll-new-pm-and-charge res', response);
        
        if (response.statusCode == 1201) {
          setTimeout(() => {
            window.location.replace(parsedData.redirect_url);
          }, 2000);
        } else {
          window.location.replace(response.next_page_url.url);
        }
      } else {
        await callApiAfterDelay(encryptedData);
      }
    } catch (error) {
      console.error('error: ', error);
      setShowMessage(true);
      if (error?.response?.status == 500) {
        const orderId = error.response.data.error.orderId
        setMessage(error.response.data.error.message);
        setApiFailureMessage(errerMessages.ERROR_REQUEST_MSG(orderId))
      } else {
        setApiFailureMessage(errerMessages.CANNOT_PROCESS_PAYMENT)
      }
    }
  };

  async function callApiAfterDelay(encryptedData) {
    let apiCount = count;
    async function callApi() {
      let response;
      if (apiCount < 10) {
        if(apiCount > 3){
          setShowMessage(true)
          setMessage(delayMessage.LONG_DELAY_MESSAGE)
        }
        response = await axios.post(
          `${envVar.saturnBaseUrl}/stripe-pay-status-v2`,
          { data: encryptedData }
        );
        console.log(`API call ${count + 1} successful! Data:`, response.data);

        if (response.data.transactionstatus == transaction_status.UNTRIED) {
          apiCount++;
          setCount(apiCount);
          await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds
          await callApi(); // Call the API again
        } else if (response.data.transactionstatus == transaction_status.OK) {
          window.location.replace(response.data.redirect_url);
        } else {
          setShowMessage(true);
          setApiFailureMessage(errerMessages.ERROR_REQUEST_MSG(response.data.orderId))
        }
      }
    }
    await callApi(); // Start making API calls
  }

  useEffect(() => {
    const { data, via, response } = params.query;
    if(!response || !data || !via){
      setApiFailureMessage(errerMessages.CANNOT_PROCESS_PAYMENT)
      setShowMessage(true);
    } else {
      const decoded = Buffer.from(response, 'base64').toString('utf8');
      const parsedData = JSON.parse(decoded);
      setData(data);
      setVia(via);
      setDecodedData(parsedData)
      getApiCall(encryptedData, via, response, parsedData);
    }

  }, []);
  console.log(count);
  return showMessage ? (
    <div className="mainContainer">
      <ErrorMessage
        cancleUrl={decodedData.cancel_url}
        failedMessage={apiFailuerMessage}
        message={message}
      />
    </div>
  ) : (
    <Spinner message={spinnerMessage.REDIRECT_MESSAGE} />
  );
}
