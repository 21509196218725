import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Card } from 'react-bootstrap'
import Spinner from '../common/Spinner';
import { decodeHtml } from '../../config/helper';
import { goBackButton } from '../../helpers';

export default function MarsSaveCard({ decodedData, base64Data, makePurchase }) {
    const [cvv, setCvv] = useState('')
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [token, setToken] = useState(null);

    const processSale = async () => {
        await makePurchase(token, cvv);
    }

    useEffect(() => {
        setLoading(true);
        const decryptData = (data) => {
            let bufferObjj = Buffer.from(data, "base64"); // Create a buffer from the string
            let decodedString = JSON.parse(bufferObjj.toString("utf8")) // Encode the Buffer as a utf8 string

            let { token } = decodedString;
            setToken(token[0]?.token);
            setData(decodedString);
            setLoading(false);
        }
        base64Data && decryptData(base64Data);
    }, [base64Data]);

    const allowOnlyNumber = (value) => {
        const re = /^[0-9\b]+$/
        if (value === '' || re.test(value)) {
            return true
        }
        return false
    }

    return (
        loading ? <Spinner message='Please wait processing sale' /> :
            <Container className='col d-flex justify-content-center mt-4'>
                <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
                <div className="back-btn-container btn btn-light" onClick={() => goBackButton(decodedData?.back_url)}>« Back</div>
                    <Card>
                        <Card.Body>
                            <Form>
                                <p className='lead'>
                                    Card Ending With {token?.last_four_digits}
                                </p>
                                <small>
                                    Please enter your CVV.
                                </small>

                                <Form.Group>
                                    <Form.Label className='float-left'>CVV </Form.Label>
                                    <Form.Control
                                        inputMode='numeric'
                                        type='tel'
                                        name='cvc'
                                        autoComplete='cc-csc'
                                        autoFocus="on"
                                        placeholder='CVV'
                                        className='shaow-sm  form-control '
                                        pattern='[0-9]*'
                                        value={cvv}
                                        minLength={3}
                                        maxLength={4}
                                        required
                                        onChange={(event) => {
                                            const value = event.target.value
                                            if (allowOnlyNumber(value)) setCvv(value)
                                        }}
                                    />
                                </Form.Group>
                                <Button
                                    className='btn btn-primary btn-block'
                                    value='proceed'
                                    type='submit'
                                    onClick={processSale}
                                    disabled={!(cvv.length >= 3)}
                                >
                                    Pay &nbsp;{decodeHtml(data.currency_sign ? data.currency_sign : data.country_info.currency_sign)} {parseFloat(data.total).toFixed(2)}
                                </Button>
                            </Form>
                        </Card.Body>

                    </Card>
                </div>
            </Container>
    )
}
