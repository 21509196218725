import React, { useState, useEffect } from 'react';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import FailurePage from '../FailurePage';
import Spinner from '../../common/Spinner';
import { errerMessages } from "../../../helpers";
import { payment_providers_names } from "../../../utils/enums";

const Error = (props) => {
  // console.log(props.match.params)
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const base64EncryptedData = decodeURIComponent(props.match.params.base64Data)

  try {
    const url = window.location.href;
    const params = queryString.parseUrl(url);
    var resObj = params.query?.reason ? JSON.parse(params.query.reason) : null;
    var errorCode = resObj?.errorCode;
    var errorMessage = resObj?.errorMessage || errerMessages.SOMETHING_WENT_WRONG;
  } catch (error) {
    console.log(error);
  }

  useEffect(() => {

    const decryptData = async (data) => {
      if (data) {
        const encryptedData = data;
        // Create a buffer from the string
        let bufferObjj = Buffer.from(encryptedData, "base64");

        // Encode the Buffer as a utf8 string
        let decodedString = JSON.parse(bufferObjj.toString("utf8"));
        const pathName = window.location.pathname;
        if (pathName.includes('/checkout/')) {
          decodedString.provider = payment_providers_names.CHECKOUT_HF;
        }
        setData(decodedString);
        setLoading(false);
      }
    }

    decryptData(base64EncryptedData)
  }, [base64EncryptedData]);

  return (
    loading ? <Spinner message='Processing, please wait...' /> :
      <FailurePage
        encryptData={props.match.params.data}
        message={errorCode}
        base64Data={props.match.params.base64Data}
        dataDecode={data}
        failedMessage={errorMessage}
      />
  );
};

export default Error;
