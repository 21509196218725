import React, { useEffect } from 'react';
import { envVar } from '../../config/baseUrl';
import { goBackButton } from '../../helpers';
import './styles.css';

const HostedForm = (props) => {
  // console.log('props', props);
  let Spreedly = window.Spreedly;

  useEffect(() => {
    Spreedly.init(envVar.spreedlyEnvironmentKey, {
      numberEl: "spreedly-number",
      cvvEl: "spreedly-cvv"
    });
  }, []);

  Spreedly.on('errors', (errors) => {
    let errorsEl = document.getElementById('errors');
    errorsEl.innerHTML = null;
    let errorBorder = "1px solid red";
    for (let i = 0; i < errors.length; i++) {
      let error = errors[i];
      if (error["attribute"]) {
        let masterFormElement = document.getElementById(error["attribute"]);
        if (masterFormElement) {
          masterFormElement.style.border = errorBorder;
        } else {
          Spreedly.setStyle(error["attribute"], "border: " + errorBorder + ";");
        }
      }
      errorsEl.innerHTML += error["message"] + "<br/>";
    }
  });

  Spreedly.on('ready', (frame) => {
    Spreedly.setFieldType('number', 'tel');
    Spreedly.setFieldType('cvv', 'tel');
    // Spreedly.setNumberFormat('maskedFormat');
    Spreedly.setNumberFormat("prettyFormat");
    Spreedly.transferFocus("number");
    Spreedly.setStyle('number', 'width: 67%; border-radius: 3px; border: 1px solid #ccc; padding: .65em .5em; font-size: 91%;');
    Spreedly.setStyle('cvv', 'width: 30%; border-radius: 3px; border: 1px solid #ccc; padding: .65em .5em; font-size: 91%;');
  });

  Spreedly.on('fieldEvent', (name, event, activeElement, inputData) => {
    if (event == 'input') {
      if (inputData["validCvv"]) {
        Spreedly.setStyle('cvv', "background-color: #CDFFE6;");
      } else {
        Spreedly.setStyle('cvv', "background-color: #FFFFFF;");
      }
      if (inputData["validNumber"]) {
        Spreedly.setStyle('number', "background-color: #CDFFE6;");
      } else {
        Spreedly.setStyle('number', "background-color: #FFFFFF;");
      }
    }
  });

  Spreedly.on("consoleError", (error) => {
    console.log("Error from Spreedly iFrame: " + error["msg"]);
  });

  const submitPaymentForm = (event) => {
    event.preventDefault();
    let normalBorder = "1px solid #ccc";

    // These are the fields whose values we want to transfer *from* the
    // master form *to* the payment frame form.
    // Add the following if you're displaying the address:
    // ['address1', 'address2', 'city', 'state', 'zip', 'country']
    let paymentMethodFields = ['first_name', 'last_name', 'month', 'year']
    let options = {};
    for (let i = 0; i < paymentMethodFields.length; i++) {
      let field = paymentMethodFields[i];

      // Reset existing styles (to clear previous errors)
      let fieldEl = document.getElementById(field);
      fieldEl.style.border = normalBorder;

      // add value to options
      options[field] = fieldEl.value;
    }

    // Reset frame styles
    Spreedly.setStyle('number', "border: " + normalBorder + ";");
    Spreedly.setStyle('cvv', "border: " + normalBorder + ";");

    // Reset previous messages
    document.getElementById('errors').innerHTML = "";
    document.getElementById('message').innerHTML = "";

    // Tokenize!
    Spreedly.tokenizeCreditCard(options);
  }

  Spreedly.on('paymentMethod', (token) => {
    let tokenField = document.getElementById("payment_method_token");
    tokenField && tokenField.setAttribute("value", token);
    props.setToken(token);
  });

  return (
    <div className='container col d-flex justify-content-center mars-container'>
      <form id="payment-form" acceptCharset="UTF-8" className="mt-4" method="POST">
        <div className="back-btn-container btn btn-light" onClick={() => goBackButton(props.decodedData?.back_url)}>« Back</div>
        <div className="spf-form display-form" id="payment-form-container">
          <input type="hidden" name="payment_method_token" id="payment_method_token" />

          <fieldset className="spf-fs-name">
            <h2>Name</h2>
            <div className="spf-field spf-field-fname">
              <label className="internal" htmlFor="spf-fname">First Name</label>
              <input type="text" className="spf-input-text" id="first_name" />
            </div>
            <div className="spf-field spf-field-lname">
              <label className="internal" htmlFor="spf-lname">Last Name</label>
              <input type="text" className="spf-input-text" id="last_name" />
            </div>
          </fieldset>

          {/* If you want billing address, uncomment */}
          {/* <fieldset className="spf-fs-address">
            <h2>Billing Address</h2>
            <div className="spf-field spf-field-street">
              <label className="internal" htmlFor="spf-street">Street Address</label>
              <input type="text" className="spf-input-text" id="address1" />
            </div>
            <div className="spf-field spf-field-street-2">
              <input type="text" className="spf-input-text" id="address2" />
            </div>
            <div className="spf-field spf-field-city">
              <label className="internal" htmlFor="spf-city">City</label>
              <input type="text" className="spf-input-text" id="city" />
            </div>
            <div className="spf-field spf-field-state">
              <label className="internal" htmlFor="spf-state">State</label>
              <input type="text" className="spf-input-text" id="state" />
            </div>
            <div className="spf-field spf-field-postcode">
              <label className="internal" htmlFor="spf-postcode">Zip Code</label>
              <input type="text" className="spf-input-text" id="zip" />
            </div>
            <div className="spf-field spf-field-country">
              <label className="internal" htmlFor="spf-country">Country</label>
              <input type="text" className="spf-input-text" id="country" />
            </div>
          </fieldset> */}

          <fieldset className="spf-fs-cc">
            <h2>Payment Details</h2>

            <div className="spf-field">
              <label className="spf-field-group spf-number">Credit Card Number</label>
              <label className="spf-field-group spf-verification_value">CVV</label>
              <div id="spreedly-number" className="spf-field-group spf-number spf-field-cc">
              </div>
              <div id="spreedly-cvv" className="spf-field-group spf-verification_value spf-field-cc">
              </div>
            </div>

            <div className="spf-field spf-field-exp">
              <label className='label-exp'>Expiration Date</label>
              <div className="spf-field-group spf-month">
                <input type="text" className="spf-input-text spf-exp" pattern='[0-9/]*' id="month" size="3" maxLength="2" placeholder="MM" />
                {/* <label className="spf-label-secondary" htmlFor="spf-exp-m">Month (MM)</label> */}
              </div>
              <span className="spf-exp-divider">/</span>
              <div className="spf-field-group spf-year">
                <input type="text" className="spf-input-text spf-exp" pattern='[0-9/]*' id="year" size="5" maxLength="4" placeholder="YYYY" />
                {/* <label className="spf-label-secondary" htmlFor="spf-exp-y">Year (YYYY)</label> */}
              </div>
            </div>
            <div className="remember-card-box">
              <input type='checkbox' className='mr-2' id='remember-card' checked={props.isChecked} name='remember' onChange={(event) => props.setIsChecked(event.target.checked)}/>
              <label className="internal" htmlFor="remember-card">Remember this card</label>
            </div>
          </fieldset>

          <fieldset className="spf-field-submit">
            <input type="button" className="button" onClick={submitPaymentForm} value="Submit Payment" />
            <div id="message"></div>
            <div id="errors"></div>
          </fieldset>
        </div>

        <div className="spreedly-iframes">
          <div id="deviceFingerprint" className="hidden">
            {/* this is supposed to be hidden/empty */}
          </div>

          <div id="hiddenChallenge" className="hidden sp3ds2-modal">
            <div id="challengeContainer" className="sp3ds2-modal-content-full">
              {/* this is supposed to be hidden/empty */}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default HostedForm;
