import React, { useState, useEffect } from 'react';
import { baseUrl, envVar } from '../../../config/baseUrl';
import { errerMessages } from "../../../helpers";
import { payment_providers_names } from '../../../utils/enums';
import axios from 'axios';

export default function APay({ encryptData, base64Data, decodedString, setError, setMessage, setFailedMessage, setLoading }) {
  const [isEnabled, setIsEnabled] = useState(false);
  const [supportedNetworks, setSupportedNetworks] = useState(envVar.aPaySupportedCardNetworks?.split(','));

  let applePayButtonEl = document.querySelector("apple-pay-button");

  let ApplePaySession = window.ApplePaySession;

  useEffect(() => {
    const checkPaymentEligibility = async () => {
      try {
        // canMakePayments - This method verifies that the device is capable of making Apple Pay payments; 
        // it doesn’t verify that the user has a provisioned card for use with Apple Pay on the device.
        // let isEligible = await ApplePaySession.canMakePayments(envVar.aPayMerchantIdentifier);
  
        // canMakePaymentsWithActiveCard - This method verifies both that the device is capable of making Apple Pay payments, and that the user has at least one provisioned card. 
        // This method asynchronously contacts the Apple Pay servers as part of the verification process.
        let isEligible = await ApplePaySession.canMakePaymentsWithActiveCard(envVar.aPayMerchantIdentifier);
  
        // show the Apple Pay button
        console.log('isEligible', isEligible);
        isEligible && setIsEnabled(true);
      } catch (error) {
        console.log('error while checkPaymentEligibility', error?.message);
      }
    }

    ApplePaySession && checkPaymentEligibility();
  }, [ApplePaySession]);

  const initiatePayment = async (dataObj) => {
    setLoading(true);
    const url = `${baseUrl.checkout}/token-pay?via=ZnJvbnRlbmQtYXBheQ==`;
    const response = await axios.post(url, { ...dataObj });
    console.log('response status: ', response?.status);
    return response;
  };

  const validateMerchant = async (validationUrl) => {
    let backendUrl = `${baseUrl.switch}/validate-session`;
    return await axios.post(backendUrl, {
      appleUrl: validationUrl,
      merchantIdentifier: envVar.aPayMerchantIdentifier,
      domainName: envVar.aPayDomainName,
      displayName: envVar.aPayMerchantDisplay,
      gateway: payment_providers_names.CHECKOUT_HF
    });
  }

  // Update supportedNetworks based on the exclude_card_networks once the component is mounted
  useEffect(() => {
    let cardList = envVar.aPaySupportedCardNetworks?.split(',') || ["visa", "masterCard", "amex", "discover"];

    const { exclude_card_networks } = decodedString;
    if (exclude_card_networks) {
      // Convert the exclude_card_networks to an array
      const excludeCardNetworksArray = exclude_card_networks?.split(',');
      // Remove the excluded card networks from the supported card networks
      const filteredList = cardList.filter(card => !excludeCardNetworksArray?.includes(card));
      cardList = filteredList;
    }
    setSupportedNetworks(cardList);
  }, [decodedString]);

  const onRequestApplePay = () => {
    if (!ApplePaySession) {
      return;
    }

    try {
      // Define ApplePayPaymentRequest
      const request = {
        countryCode: decodedString?.country_code,
        currencyCode: decodedString?.currency_name,
        merchantCapabilities: ["supports3DS"],
        supportedNetworks: supportedNetworks,
        total: { label: envVar.aPayMerchantDisplay, amount: parseFloat(decodedString?.total).toFixed(2) }
      };

      // Create ApplePaySession
      var session = new ApplePaySession(envVar.aPayVersionNumber, request);
      console.log('apple pay session created');
      session.begin();

      session.onvalidatemerchant = async (event) => {
        let validationUrl = event.validationURL;
        // Call your own server to request a new merchant session and passes it the static hostname apple-pay-gateway.apple.com as the validation URL
        await validateMerchant(validationUrl).then((res) => {
          let merchantSession = res.data?.data;
          console.log('received merchantSession');
          session.completeMerchantValidation(merchantSession);
        }).catch((error) => {
          console.error('error in validateMerchant', error);
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #AP707`);
          setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
          setError(true);
        })
      };

      session.onpaymentauthorized = async (event) => {
        try {
          let applePaymentToken = event.payment?.token;
          let response = await initiatePayment({
            data: encryptData,
            token: applePaymentToken?.paymentData,
            base64Data
          });
          
          let { status, data } = response;
          console.log('paymentauthorized: response.data', data);
          let redirect_url;
          if (status === 201) {
            session.completePayment(ApplePaySession.STATUS_SUCCESS);
            redirect_url = decodedString?.redirect_url;
            console.log("redirect_url: ", redirect_url);
            redirect_url && window.location.replace(redirect_url);
          } if (status === 202) {
            redirect_url = data?.data?.payments?._links?.redirect?.href;
            if (redirect_url) {
              session.completePayment(ApplePaySession.STATUS_SUCCESS);
            } else if (data?.message?.includes('has been already paid')) {
              redirect_url = decodedString?.redirect_url;
              session.completePayment(ApplePaySession.STATUS_FAILURE);
            }
            console.log("redirect_url: ", redirect_url);
            setLoading(false);
            redirect_url && window.location.replace(redirect_url);
          } else {
            setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #AP705`);
            setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
            session.completePayment(ApplePaySession.STATUS_FAILURE);
            setError(true);
          }
        } catch (error) {
          console.error('error', error?.message);
          if (error?.response?.data) {
            setMessage(error.response.data.message);
            setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
          } else {
            setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #AP706`);
            setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
          }
          session.completePayment(ApplePaySession.STATUS_FAILURE);
          setError(true);
        }
      };

      session.oncancel = async (event) => {
        // Payment cancelled by WebKit
        console.log('Payment cancelled by WebKit');
      };
    } catch (error) {
      console.log('onRequestApplePay: error', error);
      session.completePayment(ApplePaySession.STATUS_FAILURE);
      setMessage(`${errerMessages.CANNOT_PROCESS_PAYMENT} #AP709`);
      setFailedMessage(errerMessages.SOMETHING_WENT_WRONG);
      setError(true);
    }
  }

  if (applePayButtonEl) {
    applePayButtonEl.addEventListener('click', onRequestApplePay);
  }

  return (
    <div className='apple-pay-button-container' style={{ display: isEnabled ? 'block' : 'none' }}>
      <apple-pay-button style={{ display: 'inherit' }} buttonstyle="black" type="pay" locale="en-GB"></apple-pay-button>
    </div>
  );
}
