import React, { useState, useEffect } from 'react'
import { Container, Form, Button, Card, InputGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Spinner from '../../common/Spinner'
import FailurePage from '../FailurePage'
import { helpers, deviceInfo, trimSpace,initiateSale } from '../../../helpers';
import {decodeHtml} from '../../../config/helper';
import GpayApayContainer from '../../checkout/GpayApayContainer';
import { post_code_label_types, country_iso_code, payment_card_type } from "../../../utils/enums";

export default function CVVInfo(props) {
  const { data, token, encryptData, base64Data } = props.location.state
  //const { token, encryptData } = props.location.params;

  const [cvv, setCvv] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState('')
  const [failedMessage, setFailedMessage] = useState('')
  const [cancelUrl, setCancelUrl] = useState(data.cancel_url)
  const [providerUrl, setBaseUrl] = useState("");
  const [providerName, setProviderName] = useState("");
  const [isSameAddress, setAddressCheck] = useState(false)
  const [billingAddress, setAddress] = useState('')
  const [postCode, setPostCode] = useState('');
  const [cvv_length, setCVVLength] = useState(3);

  const onFormSubmit = (event) => {
    setLoading(true)
    event.preventDefault()
    let session_id = '';
    try{
      session_id = localStorage.kSessionId;
    }
    catch(e)
    {
      console.log("Something went wrong while accessgng - kSessionId ", e);
    }
    const card_token = token.token
    const master_token = token.master_token;
    const billing_address = trimSpace.trimWhiteSpace(billingAddress);
    const billing_post_code = trimSpace.trimWhiteSpace(postCode);
    const appendData = { 
            data: encryptData, 
            card_token, 
            cvv, 
            session_id, 
            base64Data,
            master_token, 
            billing_address, 
            billing_post_code,
            deviceInfo: deviceInfo.GetDeviceInfo()
    }

    initiateSale(
      appendData,
      setMessage,
      setLoading,
      setError,
      setFailedMessage,
      providerUrl
    );
  }

  useEffect(() => {
    var { url, name } = helpers.GetProviderConfig(data.provider);
    setBaseUrl(url);
    setProviderName(name);

    // Check if the card scheme includes AMEX, set the CVV length to 4
    const cardScheme = token.card_scheme?.toLowerCase();
    if(cardScheme?.includes(payment_card_type.AMEX)){
      setCVVLength(4);
    }

  }, [data.provider, token.card_scheme]);

  const allowOnlyNumber = (value) => {
    const re = /^[0-9\b]+$/
    if (value === '' || re.test(value)) {
      return true
    }
    return false
  }

  let postCodeLabel = post_code_label_types.POST_CODE;
  if ([country_iso_code.US, country_iso_code.CA].includes(data?.country_code)) {
    // US and CA show Zip code label instead of Post code
    postCodeLabel = post_code_label_types.ZIP_CODE;
  }

  return !error ? (
    !loading ? (
      <Container className='mt-4 col d-flex justify-content-center'>
        <div className='col-sm-6 col-md-6 col-lg-5 col-xl-4'>
        <div className='back-btn-container'>
            <Link
              style={{ fontSize: '12px', fontWeight: 500,paddingLeft:0,paddingTop:0 }}
              to={`/${providerName}?data=${encryptData}&response=${base64Data}`}
              className='nav-link text-left'
              >
              <div className="btn btn-light">« Back</div>
            </Link>
          </div>
          <GpayApayContainer data={data} encryptData={encryptData} base64Data={base64Data} setError={setError} setMessage={setMessage} setFailedMessage={setFailedMessage} setLoading={setLoading} />
          <Card>
            <Card.Body>
              <Form onSubmit={onFormSubmit}>
                <p className='lead'>
                  Card Ending With {token.last_four_digits}
                </p>
                <small>
                  Please enter your CVV.
                </small>

                <Form.Group>
                  <Form.Label className='float-left'>CVV </Form.Label>
                  <Form.Control
                    inputMode='numeric'
                    type='tel'
                    name='cvc'
                    autoComplete='cc-csc'
                    autoFocus = "on"
                    placeholder='CVV'
                    className='shaow-sm  form-control '
                    pattern='[0-9]*'
                    value={cvv}
                    minLength={3}
                    maxLength={cvv_length}
                    required
                    onChange={(event) => {
                      const value = event.target.value
                      if (allowOnlyNumber(value)) setCvv(value)
                    }}
                  />
                </Form.Group>
                { data?.billing_address_req && <Form.Group>
                  <Form.Label className=''>Billing Address</Form.Label>
                  { (trimSpace.trimData(data?.avs?.house_number)) && (trimSpace.trimData(data?.avs?.postcode)) &&
                      <span
                        style={{float:"right",fontSize:"15px"}}
                          onClick={() => {
                            setAddressCheck(!isSameAddress)
                            if(isSameAddress === false){
                              setAddress(trimSpace.trimData(data?.avs?.house_number));
                              setPostCode(trimSpace.trimData(data?.avs?.postcode));
                            }else {
                              setAddress('')
                              setPostCode('')
                            }
                          }}
                      >
                  <Form.Check type="checkbox"
                    // className='mr-2 mt-2'
                    defaultChecked={isSameAddress}
                    name='delivery_address'
                    // onChange={(event) => {
                    //   const checked = event.target.checked
                    //   setAddressCheck(checked)
                    
                    //   if(checked){
                    //     setAddress(data?.avs?.house_number);
                    //     setPostCode(data?.avs?.postcode);
                    //   }else {
                    //     setAddress('')
                    //     setPostCode('')
                    //   }
                     
                    // }}
                    label="Same as delivery" />
                    </span>}
                  <InputGroup>
                    <Form.Control
                        placeholder='Address Line 1'
                        name="house-number"
                        // disabled={isSameAddress ? true : false}
                        value={billingAddress}
                        style={{borderBottomLeftRadius:'0', borderBottom:'0', borderTopRightRadius:'0.25em'}}
                        className={` form-control`}
                        onChange={(event) => {
                          const value = event.target.value
                          setAddress(value)
                        }}
                    /> 
                    
                    <InputGroup>
                      <Form.Control
                          placeholder={postCodeLabel}
                          name="post-code"
                          // disabled={isSameAddress ? true : false}
                          value={postCode}
                          style={{ borderTopLeftRadius: '0', borderBottomRightRadius: '0.25em', borderTopRightRadius: '0', width: '50%' }}
                          className={`shadow-sm form-control`}
                          maxLength='8'
                          onChange={(event) => {
                            const value = (event.target.value).toUpperCase();
                            setPostCode(value)
                          }}
                          
                      />
                    </InputGroup>
                  </InputGroup>
                </Form.Group>}
                <Button
                  className='btn btn-primary btn-block'
                  value='proceed'
                  type='submit'
                  disabled={
                    data?.billing_address_req ? !( billingAddress.length && postCode.length && (cvv.length === cvv_length) )  : !(cvv.length === cvv_length)
                  }
                >
                  Pay &nbsp;{decodeHtml(data?.currency_sign ? data.currency_sign : data.country_info?.currency_sign)} {parseFloat(data.total).toFixed(2)}
                </Button>
              </Form>
            </Card.Body>
            {/* <div className=''>
              <Link
                style={{ fontSize: '12px', fontWeight: 500 }}
                to={`/${providerName}/?data=${encryptData}&response=${base64Data}`}
                className='nav-link text-left'
              >
                <i className='fe fe-arrow-left'>Back</i>
              </Link>
            </div> */}
          </Card>
        </div>
      </Container>
    ) : (
        <Spinner message='Please wait while we process your payment' />
      )
  ) : (
      <FailurePage
        encryptData={encryptData}
        message={message}
        cancelUrl={cancelUrl}
        base64Data={base64Data}
        dataDecode={data}
        failedMessage={failedMessage}
      />
    )
}
